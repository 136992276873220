import React from 'react';
import '../../App.css'
import APS_loc from '../../images/APSlocation.png'



class Aboutus extends React.Component{

    render(){

        return(
            <div className="page">
                <div className="info" id="cont">
                    <div className="column full">
                        <h1>About Us</h1>
                        <p>
                            Automotive Performance Solutions Pty Ltd (APS) commenced business in November 2000 and is operated by highly experienced OEM engineers as directors. The company was formed with the vision to provide professional services and guidance to the automotive industry.
                        </p>
                        <p>
                            The APS directors have worked with and assisted OEM vehicle manufacturers (both locally in Australia and abroad), in the field of program management, product design, competitor benchmarking and attribute performance along with homologation and certification testing. APS also offers specialised technician services including sophisticated prototyping, “on road” vehicle durability testing, and access to specialised test facilities and vehicle preparation.  
                        </p>
                        <p>
                            APS has transforemed to a remote buisness with partneerships with workshops around Melbourne, Victoria, Australia. 
                        </p>
                        <p>
                            APS also have CAD capabilities as well as Finite Element Analysis (FEA). 
                        </p>

                        <h2>Contact Details:</h2>

                        <p><b>Phone:</b> 0409 439 315</p>
                        <p><b>Email:</b> info@apseng.com.au</p>
                        <p><b>Mail Address:</b> P.O. BOX 416 Eltham 3095 </p>
                        <p><b>Address:</b> Remote: Epping, Melbourne, VIC </p>

                        
                    </div>
                    
                 
                    
                    

                </div>
            </div>
        )
    }

}

export default Aboutus 
