import React from 'react';
import Header from './comp/header/header'
import Aboutus from './comp/AboutUs/aboutus'
import Home from './comp/Home/home'
import VASS from './comp/VASS/VASS'
import Blog from './comp/Blog/Blog'
import Dur from './comp/Dur/Dur'
import Eng from './comp/Eng/Eng'
import BlogSingal from './comp/BlogSingal/BlogSingal'
import Footer from './comp/footer/footer'
import './App.css';


import { Route, Switch, Redirect} from 'react-router-dom'
import BLOGDATA from './comp/Blog/BlogData.json'


const Blog_data = BLOGDATA.Blog;




class App extends React.Component{
  constructor(props){
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.state = {
      page: [true, false, false, false, false],
      page_name: ["Home", "VASS","Vehicle Durability","Engineering Consultant", "About Us","Blog"],
      page_link: ["/home", "/vass","/durability","/engineering", "/aboutus","/blog"],
    };
  }

  handlePageChange(i){
    const pages = this.state.page.slice();

    for(let j=0; j<pages.length; j++){
      pages[j] =false;
    }
    pages[i] = true;


    this.setState({
      page: pages,
    });
  }

  renderHeader(){
    return(
      <Header 
        value={this.state.page}
        button_names={this.state.page_name}
        link_names={this.state.page_link}
        onClick={(i) => this.handlePageChange(i)}
      />
    );
  }


  
  render(){
   

    return (
      <div>
        <div className="appsty">
        {this.renderHeader()}  
          
        </div>
        <div>
          <Switch>
            <Route path='/home' exact component={Home} />
            <Route path='/blog' exact component={Blog} />
            <Route path='/vass' exact component={VASS} />
            <Route path='/durability' exact component={Dur} />
            <Route path='/engineering' exact component={Eng} />
            <Route path='/aboutus' exact component={Aboutus} />
            

            {Blog_data.map((Blogs) => (
    
              <Route path={'/blogs/'+Blogs.id} component={ BlogSingal } key={Blogs.id}/>
              
            ))
            }
         
              
           
             
            <Redirect to='/home' />
          </Switch>
        </div>
        <div>
            <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default App;
