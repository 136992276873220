import React from 'react';
import '../../App.css'

class Eng extends React.Component{

    render(){
        return(
            <div className="page">
                <div className="info">
                    <div className="column full">
                        <h1>Coming Soon</h1>

                    </div>
                </div>
            </div>
                    
        )
    }

}

export default Eng