import React from 'react';
import './footer.css'

class Footer extends React.Component{

    render(){
        return(

            <div >
                <footer className="footer">
                    <p><b>Phone:</b> 0409 439 315</p>
                    <p><b>Email:</b> info@apseng.com.au</p>
                    <p><b>Mail Address:</b> P.O. BOX 416 Eltham 3095 </p>
                    <p><b>Address:</b> Remote: Epping, Melbourne, VIC </p>
                    <br></br>
                    <p>Copyright © 2019.</p>
                </footer>
            </div>
        )
    }

}

export default Footer 