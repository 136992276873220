import React from 'react';
import '../../App.css'
import TestMap from '../../images/testmap.jpg'

class Dur extends React.Component{

    render(){
        return(
            <div className="page">
                <div className="info">
                    <div className="column full">
                            <h1>Vehicle Durability</h1>
                            <p>APS  believes that a vehicle's success comes from thorough durability evaluation and testing, which are performed through a combination of component and vehicle tests from prototype to production stages.</p>
                            <p>APS can manage your fleet of evaluation, durability or mileage accumulation vehicles; Test them over a range of road surfaces & loadings (city, highway, country, dirt, mountain, hot and cold weather conditions, altitude testing, rig testing, etc.) and speeds dependant on your specific requirements. APS provide regular reports on progress, datalogging, testing and measurements, and make informed engineering judgements and suggestions on any outcomes, as required or requested. Vehicles and equipment are securely and confidentially stored at our workshop facilities.</p>
                        </div>

                        <div className="column full">
                                <h2>Public Road</h2>
                                <p>On road durability testing highlights real world issues and can provide varied road types and conditions depending on the vehicle and testing required. APS   has a large database of test routes around Australia to give the necessary driving schedule breakdown.</p>
                                <p>Real world issues and can provide varied road types and conditions depending on the vehicle and testing required. APS  has a large database of test routes around Australia to give the necessary driving schedule breakdown.</p>
                            

                                

                                
                        </div>
                        <div className="column full">
                            <img src={TestMap} alt="TestMap" className="photos large"></img>
                        </div>

                        <div className="column full">
                            <h2>Secure Proving Ground</h2>
                            <p>APS can conduct testing at several secure proving grounds around Australia,- Angelsea AARC- Woomera, if sensitive prototypes are required to be tested. </p>

                            <h1>Component Testing</h1>
                            <p>APS can test your vehicle to ADR and other Australian Standards. We have access to testing facilities and equipment to enable us to independently test your vehicle or component to the required standards, whether it be for component validation, performance comparisons or certification purposes. We have extensive experience in the construction and operation of test rigs for controlled environment testing of components and/or assemblies. Our engineers on staff have vast experience with vehicle certification and are VASS signatories. 
                            </p>
                    
                        </div>
                    
                </div>
            </div>
        )
    }


}

export default Dur