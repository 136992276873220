import React from 'react';
import '../../App.css'
import '../Carousel/carosel.css'
import '../Blog/Blog.css'


import {useLocation} from 'react-router-dom'


import BLOGDATA from '../Blog/BlogData.json'


const Blog_data = BLOGDATA.Blog;

function find(id) {
    return Blog_data.find(p => p.id === id);
}

function Blog(){
    let location = useLocation();
    var id = location.pathname.split('/')[2];
    let blog = find(parseInt(id));
  
    return (
        <div  className="page"> 
                    <div className="info3" >
                        
                                <div key={blog.id}>
                                    <div className="bloglook">
                                        <div className='row'>
                                                <div className="rowtop">
                                                    <h1 className="h1">{blog.header}</h1>
                                                    < p className="p">
                                                        Date: {blog.date}
                                                    </p>
                                                    <p >
                                                        {blog.description}
                                                    </p>
                        
                                                </div>
                                                <div className="rowbottom">
                                                    <img className="photos2"        src={require(("../../" + blog.photo1))} alt="cars"></img>
                                                    <img className="photos2 mid"    src={require(("../../"  + blog.photo2))} alt="cars"></img>
                                                    <img className="photos2"        src={require(("../../"  + blog.photo3))} alt="cars"></img>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                       
                
                    </div>
                
            </div>
    );

}


class BlogSingal extends React.Component{
   

    

    render(){

        return(
            <Blog />
                
        );
    }
        

}

export default BlogSingal