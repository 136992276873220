import React from 'react';
import '../../App.css'
import TestMap from '../../images/testmap.jpg'
import TB from '../../images/TB.jpg'


class Service extends React.Component{

    data_LV_codes(){
        const LV_codes = ['Equivalent Engine Installation',
            'Performance Engine Installation',
            'Supercharger and Turbocharger Installation',
            'Engine Modifications',
            'Transmission Substitution',
            'Rear Axle Substitution',
            'Brake System Conversion',
            'Roof Conversion',
            'Modified Wheelbase Conversion',
            'Vehicle Construction',
            'Body/Chassis Variants Conversion',
            'Campervan, Motorhome Conversion',
            'Seat and Seatbelts Installation/Removal',
            'Seat and Anchorage Certification',
            'Child Restraint Anchorage Installation',
            'Construction and Installation of one-off Roll-bars and Roll-cages by Individuals',
            'Design and manufacture of commercial aftermarket roll-bars, roll-cages and other types of ROPS',
            'Installation of aftermarket roll-bars, roll-cages and ROPS',
            'Fuel Tank Alteration',
            'Installation of Liquefied Petroleum Gas (LP Gas) Fuel systems and gas containers',
            'Natural gas (NG) fuel system, installations and modification',
            'ICV – Car, Motorcycle and trike',
            'LHD Vehicle steering conversions',
            'Front suspension and steering modification',
            'Rear suspension modification',
            'High Lift - 50mm to 150mm modification',
            'Installation of Electric drivers in motor vehicles'
            ];
        const LV_codes_list = LV_codes.map((LV_codes,index) => <li key={index}>{LV_codes}</li>);
        return(<ul>{LV_codes_list}</ul>);   

    }


    render(){
        
        
        const HV_codes = [
            'Wheelchair Loader Installation',
            'Seating Capacity Alteration and Seat Belt Installation',
            'Seat Anchorage Certification and Seat Belt Anchorage Certification',
            'Cabin Conversion',
            'Wheelchair Occupant Restraint System Installation',
            'Body Mounting',
            'Chassis Frame Alteration',
            'Fuel System Alterations',
            'Tow Coupling Installation (Vehicles over 4.5 Tonne GVM)'

            ];
        const HV_codes_list = HV_codes.map((HV_codes,index) => <li key={index}>{HV_codes}</li>);
        
        const test = [
            'Engineering level of brake testing',
            'Torsion and Beaming Testing',
            'Noise Testing',
            'Bump Steer Testing',
            'Tail Pipe Emissions Testing',
            'Lane change manoeuvre test (coming in 2020)',
            'Dyno power test (coming in 2020)',
            
        ]
        const test_list = test.map((test,index) => <li  key={index}>{test}</li>);
        

        return(
            <div className="page">
                <div className="info">
                    <div className="column full">
                        
                            <h1>VASS</h1>
                            <p>APS are Vicroads accredited “Vehicle Assessment Signatory Scheme” (VASS) engineers.</p>
                            <p>
                            This means that APS can advise and guide you through your vehicle compliance needs, prior to and during the registration process of your modified or imported vehicle.
                            Professionally qualified mechanical engineers, the APS crew can help you bring your dream to registration.
                            </p>

                            <h2>Types of Modifications</h2>
                            <p>The APS team is accredited to certify the following modifications.</p>

                            <h3>Light Vehicles Modifications:</h3>
                            {this.data_LV_codes()}

                            <h3>Heavy Vehicles Modifications:</h3>
                            <ul>{HV_codes_list}</ul>

                            <p><b>Note: </b>APS may acquire further modification codes over time, please contact the team for a current list of competencies. </p>
                        
                    </div>
                    <div className="subinfo">
                        <div className="column leftpan">
                            <h2>Testing Ability</h2>
                            <p>APS is capable of conducting the following tests that may be required to obtain a VASS certification:</p>
                            <ul>{test_list}</ul>
                        </div>
                    
                        <div className="column rightpan">
                            <img src={TB} alt="Torsion and Beaming" className="photos"></img>
                        </div>
                    </div>

                    <div className="column full">
                        <h1>Vehicle Durability</h1>
                        <p>APS  believes that a vehicle's success comes from thorough durability evaluation and testing, which are performed through a combination of component and vehicle tests from prototype to production stages.</p>
                        <p>APS can manage your fleet of evaluation, durability or mileage accumulation vehicles; Test them over a range of road surfaces & loadings (city, highway, country, dirt, mountain, hot and cold weather conditions, altitude testing, rig testing, etc.) and speeds dependant on your specific requirements. APS provide regular reports on progress, datalogging, testing and measurements, and make informed engineering judgements and suggestions on any outcomes, as required or requested. Vehicles and equipment are securely and confidentially stored at our workshop facilities.</p>
                    </div>

                    <div className="column full">
                            <h2>Public Road</h2>
                            <p>On road durability testing highlights real world issues and can provide varied road types and conditions depending on the vehicle and testing required. APS   has a large database of test routes around Australia to give the necessary driving schedule breakdown.</p>
                            <p>Real world issues and can provide varied road types and conditions depending on the vehicle and testing required. APS  has a large database of test routes around Australia to give the necessary driving schedule breakdown.</p>
                        

                            

                            
                    </div>
                    <div className="column full">
                        <img src={TestMap} alt="TestMap" className="photos large"></img>
                    </div>

                    <div className="column full">
                        <h2>Secure Proving Ground</h2>
                        <p>APS can conduct testing at several secure proving grounds around Australia,- Angelsea AARC- Woomera, if sensitive prototypes are required to be tested. </p>

                        <h1>Component Testing</h1>
                        <p>APS can test your vehicle to ADR and other Australian Standards. We have access to testing facilities and equipment to enable us to independently test your vehicle or component to the required standards, whether it be for component validation, performance comparisons or certification purposes. We have extensive experience in the construction and operation of test rigs for controlled environment testing of components and/or assemblies. Our engineers on staff have vast experience with vehicle certification and are VASS signatories. 
                        </p>
                
                    </div>               
                </div>
            </div>    
        )
    }

}

export default Service 