import React from 'react';
import '../../App.css'
import Car_photos from '../../images/car1.jpg'
import Carousel from '../Carousel/Carousel'

class Home extends React.Component{

    render(){
        return(
            <div className="page">
                <div className="info2">
                    <Carousel></Carousel>
                </div>
                <div className="info home" id="cont">
                    
                        
                        <div className="column leftpan">
                            <h1>Home</h1>
                            <p>
                                APS (Automotive Performance Solutions) is a full spectrum, automotive and engineering solutions company, providing targeted responses to the issues facing the automotive industry.
                            </p>
                            <p>
                                Our experience includes commercial, industrial, recreational and military vehicles, as well as luxury automobiles, competition cars and components.
                            </p>
                            <p>
                                The automotive industry is intensely competitive. Whether catering to on road or race track vehicles, companies are constantly faced with new challenges, standards and expectations.
                            </p>
                            <p>
                                APS strives to understand, master and apply new processes and disciplines to exceed the industries expectations, ensuring that we stay ahead of the competition.
                            </p>
                            <p>
                                Combining excellent component, vehicle system and test engineering skills along with world-class facilities around Australia, APS successfully and reliably deliver confidential solutions to ensure complete customer satisfaction.
                            </p>
                        </div>
                        <div className="column rightpan">
                            <img className="photos" src={Car_photos} alt="cars"></img>
                        </div>

                    </div>       
                
            </div>
        )
    }

}

export default Home 