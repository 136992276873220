/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import './header.css'
import Logo from '../../images/APSLogo.png'

import Facebook_logo from '../../images/facebook-logo.jpg'

import { NavLink } from 'react-router-dom';

function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}



function HeaderButtons_Nav(props){
    topFunction();
    //NEED to ADD Select Stype
    return(
        <NavLink onClick={props.onClick} to={props.link} className={props.value? 'headerbuttonsSelc':'headerbuttons'}>{props.name}</NavLink>
    )
}

function Hamburger_Button_Image(){
    topFunction();
    return(
        <div>
            <div className='hambug'></div>
            <div className='hambug'></div>
            <div className='hambug'></div>
        </div>
    )
}



class Header extends React.Component{


    renderButtons_Nav(i){
        return(
            <HeaderButtons_Nav
                onClick={() => this.props.onClick(i)}
                value={this.props.value[i]}
                name={this.props.button_names[i]}
                link={this.props.link_names[i]}
            />
        )
    }

    render(){

        return (
        
                <div className='header'>
                    <div className="page2">
                        <div className='ribbinbuttons'>
                            <img className="logo" src={Logo} alt="APS"></img>
                            
                            <div className='hide-mobile'>
                                {this.renderButtons_Nav(0)}
                                {this.renderButtons_Nav(5)}
                                <div className="dropdown">
                                    <button className="headerbuttons">Services 
                                    </button>
                                    <div className="dropdown-content">
                                        {this.renderButtons_Nav(1)}
                                        {this.renderButtons_Nav(2)}
                                        {this.renderButtons_Nav(3)}
                                    </div>
                                
                                </div>
                                {this.renderButtons_Nav(4)}


                                
                            </div>
                            
                            <div className="fa2">
                                    <a href="https://www.facebook.com/APSENG01/"  >
                                        <img src={Facebook_logo} alt="APS Facebook" className="fa"></img>
                                    </a>
                            </div>
                            
                            
                            <div className='hide-desktop'>
                                <div className="dropdown" >
                                    <button className="headerbuttons">
                                        <Hamburger_Button_Image></Hamburger_Button_Image> 
                                    </button>
                                    <div className="dropdown-content">
                                        {this.renderButtons_Nav(0)}
                                        {this.renderButtons_Nav(5)}
                                        {this.renderButtons_Nav(1)}
                                        {this.renderButtons_Nav(2)}
                                        {this.renderButtons_Nav(3)}
                                        {this.renderButtons_Nav(4)}
                                        
                                            <a href="https://www.facebook.com/APSENG01/"  >
                                                <img src={Facebook_logo} alt="APS Facebook" className="fa"></img>
                                            </a>
                                        
                                    </div>
                                </div>
                            </div>
                        
                            
                        </div>

                    </div>
             </div>
   
            

            
        )
    }
}

export default Header;