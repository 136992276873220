import React from 'react';
import '../../App.css'
import '../Carousel/carosel.css'
import './Blog.css'

import BLOGDATA from '../Blog/BlogData.json'


const Blog_data = BLOGDATA.Blog;


class Blog extends React.Component{
    
    

    render(){
        
        return(
        
                <div  className="page"> 
                    <div className="info2" id="cont">
                        {  
                        Blog_data.reverse().map((blog) => {
                            return(
                                <div key={blog.id}>
                                    <div className="bloglook">
                                        <div className='row'>
                                                <div className="rowtop">
                                                    <h1 className="h1">{blog.header}</h1>
                                                            <p className="h1">
                                                            Date: {blog.date}
                                                            </p>
                                                            <p>
                                                            {blog.description}
                                                            </p>
                        
                                                </div>
                                                <div className="rowbottom">
                                                    <img className="photos2"        src={require(("../../" + blog.photo1))} alt="cars"></img>
                                                    <img className="photos2 mid"    src={require(("../../"  + blog.photo2))} alt="cars"></img>
                                                    <img className="photos2"        src={require(("../../"  + blog.photo3))} alt="cars"></img>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            );
                            
                        })
                    }
                
                    </div>
                
            </div>
        );
    }
        

}

export default Blog