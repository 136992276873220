import React from 'react';
import './carosel.css'
import '../../App.css'
import '../header/header.css'
import { Route, Link} from 'react-router-dom'
import BLOGDATA from '../Blog/BlogData.json'
import {  CSSTransition,SwitchTransition  } from 'react-transition-group';
import blockimage from '../../images/car1.jpg'

const Blog_data = BLOGDATA.Blog;



function ButtonScroll(props){
    return(
        <button onClick={props.onClick} className={props.value? 'scrollbuttonSelc':'scrollbutton'}>
            
        </button>
    )
}

function ButtonNext(props){
    return(
        <button onClick={props.onClick} className='navButton'>
            {'>'}
        </button>
    )
}

function ButtonBack(props){
    return(
        <button onClick={props.onClick} className='navButton'>
            {'<'}
        </button>
    )
}


function Blogwindow(props){


    return(
        <div >
        {  
        props.value.map((blog) => {
            return(
                <div key={blog.id}>
                    <div className="blogframe">
                        <div className='row'>
                            <div className="rowtop">
                                <h1 className="h1">{blog.header}</h1>
                                <p className="p">
                                Date: {blog.date}
                                </p>
                                <p className="p2">
                                {blog.description}
                                </p>
                                <Route>
                                    <Link to={'blogs/'+blog.id} className="link">...Continue Reading</Link>
                                    
                               </Route>  
        
                            </div>
                            <div className="rowbottom">
                                <img className="photos2"                src={require(("../../" + blog.photo1))} alt="cars"></img>
                                <img className="photos2 mid"            src={require(("../../" + blog.photo2))} alt="cars"></img>
                                <img className="photos2 last"           src={require(("../../" + blog.photo3))} alt="cars"></img>
                                <img className="photos2 photosblock"    src={ blockimage } alt="cars"></img>
                            </div>
                    </div>
                 </div>
            </div>
            );
            
        })
       
    }


    </div>
    )
}





class Carousel extends React.Component{
    constructor(props){
        super(props);
        this.handleScrollChange = this.handleScrollChange.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.renderBlogWindow = this.renderBlogWindow.bind(this);
        this.handleNextChange = this.handleNextChange.bind(this);
        this.handleBackChange = this.handleBackChange.bind(this);
        this.transition = this.transition.bind(this);
        this.state = {
            page: [true, false, false, false, false],
            currentpage: 0,
            currentblog: Blog_data.filter(x => x.id === Blog_data.length-1),
            maxblog: Blog_data.length-1, 
            transNext: true,
        }
    }

    componentDidMount() {
        Blog_data.map((blog) => {
            const image1 = new Image();
            image1.src = blog.photo1;
            const image2 = new Image();
            image2.src = blog.photo2;
            const image3 = new Image();
            image3.src = blog.photo3;
            return(image3);
        });

        this.timerID = setInterval(
          () => this.handleNextChange(this.state.currentpage),
          5000
        );
      }
      componentWillUnmount() {
        clearInterval(this.timerID);
      }

    handleScrollChange(i){
        const pages = this.state.page.slice();
        const maxblog = this.state.maxblog;
        var trans;

        for(let j=0; j<pages.length; j++){
            pages[j] =false;
        }
        pages[i] = true; 

        if(i === this.state.currentpage){
            trans = this.state.transNext;
        }else{
            trans = !this.state.transNext;
        }
        this.componentWillUnmount(); 
        this.componentDidMount(); 

        this.setState({
            page: pages,
            currentpage: i,
            currentblog:Blog_data.filter(x => x.id === maxblog-i),
            transNext: trans,
        });
    }



    handleNextChange(i){
        const pages = this.state.page.slice();
        var currentpages = this.state.currentpage;
        const maxblog = this.state.maxblog;



        for(let j=0; j<pages.length; j++){
            pages[j] =false;
        }
        if( i === pages.length-1){
            pages[0] = true;
            currentpages = 0;
        } else {
            pages[i+1] = true;
            currentpages = currentpages+1;
        }
        this.componentWillUnmount(); 
        this.componentDidMount(); 
        
        //new EventTarget();
        this.setState({
            page: pages,
            currentpage: currentpages,
            currentblog:Blog_data.filter(x => x.id === (maxblog-currentpages)),
            transNext: !this.state.transNext,
            
        });
    }

    handleBackChange(i){
        const pages = this.state.page.slice();
        var currentpages = this.state.currentpage;
        const maxblog = this.state.maxblog;

        for(let j=0; j<pages.length; j++){
            pages[j] =false;
        }
        if( i === 0){
            pages[pages.length-1] = true;
            currentpages = pages.length-1;
        } else {
            pages[i-1] = true;
            currentpages = currentpages-1;
        }
        this.componentWillUnmount(); 
        this.componentDidMount(); 
        this.setState({
            page: pages,
            currentpage: currentpages,
            currentblog:Blog_data.filter(x => x.id === (maxblog-currentpages)),
            transNext: !this.state.transNext,
        });
    }

    renderScrollButton(i){
        return(
            <ButtonScroll
                onClick={() => this.handleScrollChange(i)}
                value={this.state.page[i]}
            />
        )
    }

    renderBlogWindow(){
        return(
            <Blogwindow value={this.state.currentblog} /> 

        )
    }

    renderNextButton(i){
        return(
            <ButtonNext
                onClick={() => this.handleNextChange(i)}
                value={this.state.page[i]}
            />
        )
    }
    renderBackButton(i){
        return(
            <ButtonBack
                onClick={() => this.handleBackChange(i)}
                value={this.state.page[i]}
            />
        )
    }
    transition(){
       

        let currentComponent = null;
        for(let j=0; j<5; j++){
            if(j === this.state.currentpage)
            {
                currentComponent = <Blogwindow value={this.state.currentblog} />; 
            }
        }


        return (
            <div>
                <SwitchTransition >
                    
                    <CSSTransition
                        key={this.state.transNext}
                        addEndListener={(node, done) => {
                            // use the css transitionend event to mark the finish of a transition
                            node.addEventListener('transitionend', done, false);
                          }}
                        classNames="fade"
                        
                       
                    >
                    
                    {currentComponent}
                    
                    </CSSTransition>
                   
                </SwitchTransition>
        </div>
        );
    }

    render(){
        return(
            <div className="page">
                
                    <div className='Carosel'>
                        <div className='navButtonPan'>
                            {this.renderBackButton(this.state.currentpage)}
                        </div>
                        
                        {this.transition()}

                    
                        

                        <div className='navButtonPan'>
                            {this.renderNextButton(this.state.currentpage)}
                        </div>

                    </div>
                    <div className='scrollbuttonpan'>
                            {this.renderScrollButton(0)}
                            {this.renderScrollButton(1)}
                            {this.renderScrollButton(2)}
                            {this.renderScrollButton(3)}
                            {this.renderScrollButton(4)}

                    </div>
           
            </div>
        )
    }
}

export default Carousel;


